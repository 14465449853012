<template>
	<footer>
		<div class="main-footer">
			<div class="custom-container d-flex justify-content-between align-items-start">
				<div class="logo">
					<img src="../assets/images/compower-logo-white.svg" alt="Com-Power">
					<p>© Com-Power Corporation - Supplier of Standard-Compliant EMI EMC Test Equipment</p>
				</div>
				<ul class="links">
					<h4>Services</h4>
					<li><router-link :to = "{ name: 'DistributorsPage' }">Distributors</router-link></li>
					<li><router-link :to = "{ name: 'ContactPage' }">Catalog</router-link></li>
					<li><router-link :to = "{ name: 'ProductPage' }">Quote</router-link></li>
					<li><router-link :to = "{ name: 'ApplicationNotes' }">Tech Notes</router-link></li>
				</ul>
				<ul class="links">
					<h4>About</h4>
					<li><router-link :to = "{ name: 'AboutPage' }">Who we are</router-link></li>
					<li><router-link :to = "{ name: 'ComplianceTesting' }">What we do</router-link></li>
					<li><router-link :to = "{ name: 'ProductPage' }">Product</router-link></li>
					<li><router-link :to = "{ name: 'ContactPage' }">Contact Us</router-link></li>
				</ul>
				<ul class="links">
					<h4>Other</h4>
					<li><router-link :to = "{ name: 'OrderingInstructions'}">Ordering Instruction</router-link></li>
					<li><router-link :to = "{ name: 'WarrantyInfo'}">Warranty</router-link></li>
					<li><router-link :to = "{ name: 'IndustryLinks'}">Industry links</router-link></li>
					<li><router-link :to = "{ name: 'BlogsPage' }">Blog</router-link></li>
				</ul>
				<ul class="links">
					<h4>Get in Touch</h4>
					<li><a href="mailto:sales@com-power.com">sales@com-power.com</a></li>
					<li><a href="tel:(949) 459-9600">(949) 459-9600</a> ,<br> Toll free: <a href="tel:(855) EMI-2-EMC (364-2362)">(855) EMI-2-EMC (364-2362)</a></li>
					<li><a href="">19121 El Toro Rd, Silverado, CA 92676.</a></li>
				</ul>
			</div>
		</div>
		<div class="bottom-footer">
			<div class="custom-container d-flex justify-content-between align-items-center">
				<p>© <strong>{{currentYear}} Com-Power,</strong> All Rights Reserved</p>
				<a href="https://wvelabs.com/" target="_blank"><img src="../assets/images/wve-icon.png" alt="Design & Develop by Wve Labs"></a>
				<!-- <ul class="d-flex justify-content-between align-items-center">
					<li><a href="">Privacy Policy</a></li>
					<li>|</li>
					<li><a href="">Terms & Conditions</a></li>
				</ul> -->
			</div>
		</div>
  </footer>
</template>

<script>
	export default {
		name: 'MainFooter',
		data() {
			return {
				currentYear: new Date().getFullYear(), // 2020
			};
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
