<template>
    <header :class="{ shrink: isShrunk }">
      <div class="top-header">
          <div class="custom-container">
              <ul class="d-flex justify-content-end">
                  <li><a href="tel:(855) 364-2362" title="(855) 364-2362"><img src="../assets/images/phone.svg" alt="Phone"> (855) 364-2362</a></li>
                  <li><a href="mailto:sales@com-power.com" title="sales@com-power.com"><img src="../assets/images/mail.svg" alt="Mail">sales@com-power.com</a></li>
              </ul>
          </div>
      </div>
      <div class="main-header">
          <div class="custom-container">
              <div class="d-flex justify-content-between align-items-center">
                  <div class="logo">
                      <router-link :to="{name:'Home'}"><img src="../assets/images/com-power-logo.png" alt="Com-Power"></router-link>
                  </div>
                  <div class="nav">
                      <ul class="d-flex justify-content-between align-items-center">
                          <button @click="toggleSidebar" class="menu-close-btn">
                              <img src="../assets/images/close.svg" alt="Com-Power">
                          </button>
                          <li>
                              <div class="dropdown" @click.stop>
                                  <button
                                    @click="toggleDropdown('product')"
                                    :class="{ active: isRouteActive(['ProductPage', 'CategoryProductPage']) || openDropdown === 'product' }"
                                  >
                                    Products
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                                      <path d="M9 1L5 5L1 1" stroke="#24315D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                  </button>
                                  <ul v-if="openDropdown === 'product'"  @click="handleClick">
                                      <li v-for="category in categories" :key="category.id" :class="{ 'active': isActiveCategory(category.slug) }">
                                          <router-link :to="{ name: 'CategoryProductPage', params: { slug: category.slug } }" @click="closeDropdown">
                                          {{ category.title }}
                                          </router-link>
                                      </li>
                                  </ul>
                              </div>
                          </li>
                          <li>
                              <div class="dropdown" @click.stop>
                                  <button @click="toggleDropdown('sales')" :class="{ active: isRouteActive(['RequestQuote', 'DistributorsPage' , 'CallBackRequest' ]) }">Sales
                                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                                          <path d="M9 1L5 5L1 1" stroke="#24315D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                      </svg>
                                  </button>
                                  <ul v-if="openDropdown === 'sales'" @click="handleClick">
                                      <li :class="{ 'active': $route.name === 'RequestQuote' }"><router-link class="redirection" :to = "{ name: 'RequestQuote' }">Request Quote</router-link></li>
                                      <li :class="{ 'active': $route.name === 'DistributorsPage' }"><router-link class="redirection" :to = "{ name: 'DistributorsPage' }">Distributors</router-link></li>
                                      <li :class="{ 'active': $route.name === 'CallBackRequest' }"><router-link class="redirection" :to = "{ name: 'CallBackRequest' }">Callback Request</router-link></li>
                                  </ul>
                              </div>
                          </li>
                          <li>
                              <div class="dropdown" @click.stop>
                                  <button @click="toggleDropdown('calibration')" :class="{ active: isRouteActive(['CalibrationPage', 'CalibrationPage' ]) }">Calibration
                                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                                          <path d="M9 1L5 5L1 1" stroke="#24315D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                      </svg>
                                  </button>
                                  <ul v-if="openDropdown === 'calibration'"  @click="handleClick">
                                      <li :class="{ 'active': $route.name === 'CalibrationPage' }"><router-link :to = "{ name: 'CalibrationPage' }">17025 Accredited</router-link></li>
                                      <li :class="{ 'active': $route.name === 'CalibrationRepairRequest' }"><router-link :to = "{ name: 'CalibrationRepairRequest' }">NIST Traceable</router-link></li>
                                  </ul>
                              </div>
                          </li>
                          <li :class="{ 'active': $route.name === 'RepairRequest' }" @click="handleClick"><router-link :to = "{ name: 'RepairRequest' }">Repair Request</router-link></li>
                          <li :class="{ 'active': $route.name === 'ApplicationNotes' }" @click="handleClick"><router-link :to = "{ name: 'ApplicationNotes' }">Tech Notes</router-link></li>
                          <li :class="{ 'active': $route.name === 'BlogsPage' }" @click="handleClick"><router-link :to = "{ name: 'BlogsPage' }">Blog</router-link></li>
                          <li>
                              <div class="dropdown" @click.stop>
                                  <button @click="toggleDropdown('company')" :class="{ active: isRouteActive(['AboutPage', 'ComplianceTesting' , 'ContactPage', 'CareersPage' ]) }">Company
                                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
                                          <path d="M9 1L5 5L1 1" stroke="#24315D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                      </svg>
                                  </button>
                                  <ul v-if="openDropdown === 'company'"  @click="handleClick">
                                      <li :class="{ 'active': $route.name === 'AboutPage' }"><router-link :to = "{ name: 'AboutPage' }">About Us</router-link></li>
                                      <li :class="{ 'active': $route.name === 'ComplianceTesting' }" ><router-link :to = "{ name: 'ComplianceTesting' }">EMC Compliance Testing</router-link></li>
                                      <li :class="{ 'active': $route.name === 'ContactPage' }"><router-link :to = "{ name: 'ContactPage' }">Contact Us</router-link></li>
                                      <li :class="{ 'active': $route.name === 'CareersPage' }"><router-link :to = "{ name: 'CareersPage' }">Careers</router-link></li>
                                  </ul>
                              </div>
                          </li>
                      </ul>
                  </div>
                  <div class="search-cart">
                      <ul class="d-flex justify-content-end align-items-center">
                            <li><a href="" class="cart"><img src="../assets/images/cart.svg" alt="Cart"><span class="cart" id="cart-count"></span></a></li>
                            <li class="search-div" ref="searchDiv">
                                <img src="../assets/images/search.svg" alt="Search" @click="toggleSearch">
                                <div v-if="showSearch" class="search-container">
                                    <input 
                                        type="text" 
                                        placeholder="Search Product" 
                                        class="search-input"
                                        v-model="searchQuery" 
                                        @keyup="filterProducts" 
                                    >
                                    <ul v-if="filteredProducts.length" class="dropdown">
                                        <li v-for="product in filteredProducts" :key="product.id">
                                            <router-link :to="{ name: 'SearchResultPage', query: { searchTerm: product.text } }">{{ product.text }}</router-link> 
                                        </li>
                                    </ul>
                                    <button class="close-search" @click="toggleSearch">
                                        <img src="../assets/images/close.svg" alt="Com-Power">
                                    </button>
                                </div>
                            </li>
                            <li><router-link :to = "{ name: 'RequestQuote' }" class="common-btn">Request a Quote</router-link></li>
                            <button @click="toggleSidebar" class="menu-box">
                                <span></span><span></span><span></span>
                            </button>
                      </ul>
                  </div>
              </div>
          </div>
      </div>
    </header>
  </template>
  <script>
      import { fetchCategoryData } from '@/utils/categoryUtils';
    //   import debounce from 'lodash/debounce';
      import axios from 'axios';
      export default {
          name: 'MainHeader',
          data() {
              return {
                    openDropdown: null,
                    isShrunk: false,
                    categories: [],
                    showSearch: false,       // Controls search input visibility
                    searchQuery: '',         // User's search input
                    products: [],            // List of products to search from
                    filteredProducts: []     // Filtered pro
              };
          },
          mounted() {
              document.addEventListener('click', this.closeDropdown);
              window.addEventListener("scroll", this.handleScroll);
              this.loadCategories(true);
              this.getCartCount();
              document.addEventListener('click', this.handleClickOutside);
          },
          methods: {
            toggleSearch() {
                this.showSearch = !this.showSearch;  // Toggle search input visibility
            },
            
            filterProducts() {
                if (this.searchQuery.length >= 2) {  // Start searching after 3 characters
                    axios
                        .get(`/frontend/search-tearm?searchTerm=${this.searchQuery}`)
                        .then((response) => {
                            this.filteredProducts = response.data.data;
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                } else {
                    this.filteredProducts = [];  // Clear results if query is too short
                }
            },
            toggleDropdown(dropdown) {
                this.openDropdown = this.openDropdown === dropdown ? null : dropdown;
            },
            toggleSidebar() {
                document.body.classList.toggle('menu-open');
            },
            handleClickOutside(event) {
                if (this.showSearch && !this.$refs.searchDiv.contains(event.target)) {
                    this.toggleSearch();
                }
            },
            closeDropdown() {
                this.openDropdown = null;
            },
            isRouteActive(routeName) {
                return routeName.includes(this.$route.name);
            },
            handleScroll() {
                this.isShrunk = window.scrollY > 100;
            },
            isActiveCategory(slug) {
                return this.$route.name === 'CategoryProductPage' && this.$route.params.slug === slug;
            },
            async loadCategories(isAscending) {
                try {
                    this.categories = await fetchCategoryData(isAscending);
                } catch (error) {
                    console.error("Failed to load categories:", error);
                }
            },
            async getCartCount() {
                // Get cart count from session storage
                const cartCount = localStorage.getItem('quote') ? JSON.parse(localStorage.getItem('quote')).length : 0;
                document.getElementById('cart-count').innerHTML = cartCount;
            },
            handleClick(event) {
                // Handle click on any 'router-link' or 'li'
                if (event.target.tagName === 'A' || event.target.tagName === 'LI') {
                    document.body.classList.remove('menu-open');
                }
            },
        },
        beforeUnmount() {
            document.removeEventListener('click', this.closeDropdown);
            window.removeEventListener("scroll", this.handleScroll);
            document.removeEventListener('click', this.handleClickOutside);
        }
    }
  </script>
