import { createRouter, createWebHistory } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import InnerLayout from '@/layouts/InnerLayout.vue'

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import('@/views/HomePage'),
		meta: { layout: DefaultLayout },
		requiresAdminAuth: false
	},
	{
		path: '/about-us',
		name: 'AboutPage',
		component: () => import('@/views/AboutPage'),
		meta: { layout: DefaultLayout },
		requiresAdminAuth: false
	},
	{
		path: '/contact-us',
		name: 'ContactPage',
		component: () => import('@/views/ContactPage'),
		meta: { layout: DefaultLayout },
		requiresAdminAuth: false
	},
	{
		path: '/compliance-testing',
		name: 'ComplianceTesting',
		component: () => import('@/views/ComplianceTesting'),
		meta: { layout: DefaultLayout },
		requiresAdminAuth: false
	},
	{
		path: '/careers',
		name: 'CareersPage',
		component: () => import('@/views/CareersPage'),
		meta: { layout: DefaultLayout },
		requiresAdminAuth: false
	},
	{
		path: '/distributors',
		name: 'DistributorsPage',
		component: () => import('@/views/DistributorsPage'),
		meta: { layout: DefaultLayout },
		requiresAdminAuth: false
	},
	{
		path: '/productquotereq-emc',
		name: 'RequestQuote',
		component: () => import('@/views/RequestQuote'),
		meta: { layout: DefaultLayout },
		requiresAdminAuth: false
	},
	{
		path: '/callback-request',
		name: 'CallBackRequest',
		component: () => import('@/views/CallBackRequest'),
		meta: { layout: DefaultLayout },
		requiresAdminAuth: false
	},
	{
		path: '/calibration',
		name: 'CalibrationPage',
		component: () => import('@/views/CalibrationPage'),
		meta: { layout: DefaultLayout },
		requiresAdminAuth: false
	},
	{
		path: '/calibration/scope',
		name: 'CalibrationScope',
		component: () => import('@/views/calibration/CalibrationScope'),
		meta: { layout: InnerLayout },
		requiresAdminAuth: false
	},
	{
		path: '/calibration/request',
		name: 'CalibRequest',
		component: () => import('@/views/calibration/CalibRequest'),
		meta: { layout: InnerLayout },
		requiresAdminAuth: false
	},
	{
		path: '/calibration/lisncalib',
		name: 'LisnCalibrationForm',
		component: () => import('@/views/calibration/LisnCalibrationForm'),
		meta: { layout: InnerLayout },
		requiresAdminAuth: false
	},
	{
		path: '/calibration/cdncalib',
		name: 'CdnCalibrationForm',
		component: () => import('@/views/calibration/CdnCalibrationForm'),
		meta: { layout: InnerLayout },
		requiresAdminAuth: false
	},
	{
		path: '/calibration/isncalib',
		name: 'IsnCalibrationForm',
		component: () => import('@/views/calibration/IsnCalibrationForm'),
		meta: { layout: InnerLayout },
		requiresAdminAuth: false
	},
	{
		path: '/calibration/currentprobecalib',
		name: 'CurrentProbeCalibrationForm',
		component: () => import('@/views/calibration/CurrentProbeCalibrationForm'),
		meta: { layout: InnerLayout },
		requiresAdminAuth: false
	},
	{
		path: '/calibration/preamlifiercalib',
		name: 'PreamplifierCalibrationForm',
		component: () => import('@/views/calibration/PreamplifierCalibrationForm'),
		meta: { layout: InnerLayout },
		requiresAdminAuth: false
	},
	{
		path: '/repair-request',
		name: 'RepairRequest',
		component: () => import('@/views/RepairRequest'),
		meta: { layout: DefaultLayout },
		requiresAdminAuth: false
	},
	{
		path: '/tech-notes',
		name: 'ApplicationNotes',
		component: () => import('@/views/ApplicationNotes'),
		meta: { layout: DefaultLayout },
		requiresAdminAuth: false
	},
	{
		path: '/blog',
		name: 'BlogsPage',
		component: () => import('@/views/BlogsPage'),
		meta: { layout: DefaultLayout },
		requiresAdminAuth: false
	},
	{
		path: '/blog/:slug',
		name: 'BlogDetails',
		component: () => import('@/views/BlogDetails'),
		meta: { layout: DefaultLayout},
		requiresAdminAuth: false
	},
	{
		path: '/demo',
		name: 'DemoSlider',
		component: () => import('@/views/DemoSlider'),
		meta: { layout: DefaultLayout },
		requiresAdminAuth: false
	},
	{
		path: '/products',
		name: 'ProductPage',
		component: () => import('@/views/ProductPage'),
		meta: { layout: DefaultLayout },
		requiresAdminAuth: false
	},
	{
		path: '/products/:slug',
		name: 'CategoryProductPage',
		component: () => import('@/views/CategoryProductPage'),
		meta: { layout: DefaultLayout },
		requiresAdminAuth: false
	},
	{
		path: '/products/:slug',
		name: 'SubCategoryPage',
		component: () => import('@/views/SubCategoryPage'),
		meta: { layout: DefaultLayout },
		requiresAdminAuth: false
	},
	{
		path: '/products/:slug/:categoryslug',
		name: 'SubCategoryProductPage',
		component: () => import('@/views/SubCategoryProductPage'),
		meta: { layout: DefaultLayout },
		requiresAdminAuth: false
	},
	{
		path: '/products/:slug/:categoryslug',
		name: 'CategoryProductDetails',
		component: () => import('@/views/CategoryProductDetails'),
		meta: { layout: DefaultLayout },
		requiresAdminAuth: false
	},
	{
		path: '/products/:slug/:categoryslug/:productslug',
		name: 'ProductDetailsPage',
		component: () => import('@/views/SubCategoryProductDetailsPage'),
		meta: { layout: DefaultLayout },
		requiresAdminAuth: false
	},
	{
		path: '/products-details',
		name: 'ProductDetails',
		component: () => import('@/views/ProductDetails'),
		meta: { layout: DefaultLayout },
		requiresAdminAuth: false
	},
	{
		path: '/get-a-calibration-request',
		name: 'CalibrationRepairRequest',
		component: () => import('@/views/CalibrationRequest'),
		meta: { layout: DefaultLayout },
		requiresAdminAuth: false
	},
	{
		path: '/instructions',
		name: 'OrderingInstructions',
		component: () => import('@/views/OrderingInstructions'),
		meta: { layout: DefaultLayout },
		requiresAdminAuth: false
	},
	{
		path: '/warranty',
		name: 'WarrantyInfo',
		component: () => import('@/views/WarrantyInfo'),
		meta: { layout: DefaultLayout },
		requiresAdminAuth: false
	},
	{
		path: '/industry',
		name: 'IndustryLinks',
		component: () => import('@/views/IndustryLinks'),
		meta: { layout: DefaultLayout },
		requiresAdminAuth: false
	},
	{
		path: '/search',
		name: 'SearchResultPage',
		component: () => import('@/views/SearchResult'),
		meta: { layout: DefaultLayout },
		requiresAdminAuth: false
	},
	{
		path: '/thank-you',
		name: 'ThankYouPage',
		component: () => import('@/views/ThankYouPage'),
		meta: { layout: DefaultLayout },
		requiresAdminAuth: false
	},
	{
		path: '/page-not-found',
		name: '404Page',
		component: () => import('@/views/404Page'),
		meta: { layout: DefaultLayout },
		requiresAdminAuth: false
	},
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        // always scroll to top
        return { top: 0 };
    },
})

router.beforeEach(async(to, from, next) => {
	// Set the title based on route meta
	document.title = to.meta.title || 'Com-Power Corporation'; // Default title if not set
	// Determine the layout based on the meta property of the route
	const layout = to.meta.layout || DefaultLayout;
	// Set the meta tags
	if (router.app) {
		router.app.$options.components.Layout = layout;
	}

    next();
});

export default router