import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import axios from 'axios';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import { API_URL } from '@/../urlConstants';
import ToastPlugin from 'vue-toast-notification';
axios.defaults.baseURL = API_URL;
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.common['Access-Control-Allow-Methods'] = '*';
axios.defaults.headers.common['Accept'] = 'application/json';

const app = createApp(App)

app.use(router)
app.mount('#app')
app.use(ToastPlugin)

import '../src/assets/css/fonts.css'
import '../src/assets/css/custom.css'
import '../src/assets/css/responsive.css'